import { Card, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { getCompany, isAuth } from "../../../actions/auth"
import { getUsersGroup } from "../../../actions/group"
import FilterMultiSelect from "../FilterMultiSelect"
import FilterUser from "../FilterUser"
import useReportUserFilter from "./useReportUserFilter"
import { useSelector } from "react-redux"

interface useUserFilterAvanced {
    useAsUserInit?: any; // Usuario con modelo isAuth()
    key?: string,
    roles?: ('user' | 'super' | 'comercial' | 'admin')[],
    useReportId?: [string, number]
    keyCode: string
}

export default ({useAsUserInit, key = '_id', roles = ['user'], useReportId, keyCode }:useUserFilterAvanced) => {
    const [
        {
            activeUsers,
            activeGroups,
            userFilter,
            groupFilter,
            actualUser,
            usersExtraFilters,
            allUsers,
            // groupUsers,
            useAsUser
        },
        {
            setActiveGroups,
            setActiveUsers,
            setUserFilter,
            setGroupFilter,
            setActualUser,
            setUsersExtraFilters,
            setAllUsers,
            setGroupUsers,
            setUseAsUser
        }
    ] = useReportUserFilter({useAsUserInit, useReportId, keyCode})




    const filterThisUser = (u, users_filters) => {
        let result = u.active && u.roles.includes('user') && !u.demo
        if (!result) return false
        Object.keys(users_filters).map((keyField) => {
            let filerArray = users_filters[keyField]
            if (filerArray.length === 0 || filerArray[0] === 'all') return false
            if (!u.additionalData) return result = false
            let valueField = u.additionalData[keyField]
            if (!filerArray.includes(valueField)) result = false
        })

        return result
    }

    const calculateGroupActive = (value, users_filters) => {
        let listUsers = []
        let listUsersId = []
        if (value.length == 0 || value[0] == 'all') {
            activeGroups.map((r) => {
                listUsers = [...listUsers, ...r.users.filter((u) => filterThisUser(u, users_filters))].sort((a,b) => `${a.name} ${a.lastname}`.localeCompare(`${b.name} ${b.lastname}`))
            })
        } else {
            activeGroups.map((r) => {
                if (
                    value.includes(r._id)
                ) {
                    listUsers = [...listUsers, ...r.users.filter((u) => filterThisUser(u, users_filters))].sort((a,b) => `${a.name} ${a.lastname}`.localeCompare(`${b.name} ${b.lastname}`))
                }
            })
        }
        listUsersId = [...listUsers.map((u) => u[key])]

        if (userFilter && userFilter[0] != 'all') {
            setUserFilter(userFilter.filter((u) => listUsersId.includes(u)))
        } else {
            setUserFilter(['all'])
        }

        return listUsers
    }

    const handleActiveGroupChange = (value) => {
        let listUsers = calculateGroupActive(value, usersExtraFilters)
        setActiveUsers(listUsers)
        setGroupFilter(value)
    }

    const handleUsersFilters = (key_filter) => (value) => {
        let newUsersExtraFilters = { ...usersExtraFilters, [key_filter]: value }
        let listUsers = calculateGroupActive(groupFilter, newUsersExtraFilters)
        setUsersExtraFilters(newUsersExtraFilters)
        setActiveUsers(listUsers)
    }

    let groupQuery = useQuery({
        queryKey: ['user-group'], 
        queryFn: getUsersGroup
    })


    const usesRole = (user) => {
        if(!roles || roles.length == 0) return true
        let validRoles = roles.filter((rol) => {
            if(user.roles.includes(rol)) return true
        })
        return validRoles.length > 0

    }

    useEffect(() => {
        let userMe = useAsUser || isAuth()
        setActualUser(userMe)
        if (!groupQuery.data) return () => {}
        let result = groupQuery.data
        setActualUser(userMe)

        if (userMe && userMe.roles && (userMe.roles.includes('comercial') || userMe.roles.includes('super'))) {
            if (!result || result.error) return () => {}
            let groups_result = []
            let listUsers = []
            let listAllUsers = []
            let listGroupUsers = []
            if (useAsUser && isAuth() && useAsUser.group._id != isAuth().group._id) {
                result.map((r) => {
                    listAllUsers = [...listAllUsers, ...r.users]
                    if (r.ancestors && (r.ancestors.includes(useAsUser.group._id) || r._id == useAsUser.group._id)) {
                        listUsers = [...listUsers, ...r.users.filter((u) => u.active && !u.demo && usesRole(u))]
                        listGroupUsers = [...listGroupUsers, ...r.users]
                        groups_result.push(r)
                    }
                })
            } else {
                result.map((r) => {
                    listAllUsers = [...listAllUsers, ...r.users]
                    listUsers = [...listUsers, ...r.users.filter((u) => u.active && !u.demo && usesRole(u))]
                    listGroupUsers = [...listGroupUsers, ...r.users]
                })
                groups_result = result
            }
            setAllUsers(listAllUsers.sort((a,b) => `${a.name} ${a.lastname}`.localeCompare(`${b.name} ${b.lastname}`)))
            setActiveUsers(listUsers.sort((a,b) => `${a.name} ${a.lastname}`.localeCompare(`${b.name} ${b.lastname}`)))
            setGroupUsers(listGroupUsers.sort((a,b) => `${a.name} ${a.lastname}`.localeCompare(`${b.name} ${b.lastname}`)))
            setActiveGroups(groups_result.filter((g) => g.users.length > 0))
        }
    }, [useAsUser, groupQuery.data])

    let altUserFilter = []


    if (actualUser && actualUser.roles && (actualUser.roles.includes('comercial') || actualUser.roles.includes('super'))) {
        if ((useAsUser && isAuth() && useAsUser.group?._id != isAuth().group._id) || groupFilter[0] != 'all' || Object.keys(usersExtraFilters).filter((key_field) => usersExtraFilters[key_field].length > 0 && usersExtraFilters[key_field][0] != 'all').length > 0) {
            if (userFilter[0] == 'all' || userFilter.length == 0) {
                altUserFilter = activeUsers.map((u) => u[key])
            } else {
                altUserFilter = userFilter
            }
        } else {
            altUserFilter = userFilter
        }
    } else {
        if(actualUser && actualUser._id !== isAuth()._id){
            altUserFilter = [actualUser[key]]
        }
    }


    return [
        {
            usersFilter: altUserFilter,
            usersExtraFilters,
            groupFilter,
            activeGroups,
            userFilter,
            activeUsers,
            key,
            useAsUser,
            actualUser,
            allUsers
        },
        {
            setUseAsUser,
            handleActiveGroupChange,
            handleUsersFilters,
            setUserFilter,
        }
    ]
}