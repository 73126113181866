import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { zodResolver } from "@hookform/resolvers/zod"
import moment from "moment"
import { useEffect } from 'react'
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../ui/button"
import { CustomFormField } from "../ui/custom-form-field"
import { Form } from "../ui/form"
import { DateTimePickerWithHandleError } from "../ui/Time-picker/date-time-picker"

moment().locale()

interface FilterDateModal {
    open: boolean,
    setOpen: (value: boolean) => void,
    setValue: (startDate: string, endDate: string) => void,
    format?: string
};

const rangeDateFormSchema = z.object({
    start: z.date(),
    end: z.date()
});

type RangeDateFormSchemaType = z.infer<typeof rangeDateFormSchema>;

const defaultValues = {
    end: undefined,
    start: undefined
}

const FilterDateModal = (props: FilterDateModal) => {
    const { open, setOpen, setValue, format } = props;

    const contextForm = useForm<RangeDateFormSchemaType>({
        resolver: zodResolver(rangeDateFormSchema),
        defaultValues
    });

    const isValidForm = contextForm.formState.isValid;

    useEffect(() => {
        contextForm.reset(defaultValues);
    }, [open])

    const onSubmit = (values: RangeDateFormSchemaType) => {
        const { start, end } = values;

        if (format) {
            if (start) {
                if (end) {
                    setValue(moment(start).add(-1, 'd').format(format), moment(end).add(1, 'd').format(format))
                } else {
                    setValue(moment(start).add(-1, 'd').format(format), end)
                }
            } else if (end) {
                setValue(start, moment(end).add(1, 'd').format(format))
            } else {
                setValue(start, end)
            }
        } else {
            setValue(start?.toISOString(), moment(end).endOf('day').toISOString())
        }

        setOpen(false);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>
                        Elija un periodo
                    </DialogTitle>
                </DialogHeader>
                <Form {...contextForm}>
                    <form
                        onSubmit={contextForm.handleSubmit(onSubmit)}
                        className="flex flex-col gap-4"
                        id="filterRangeDate"
                    >
                        <CustomFormField
                            control={contextForm.control}
                            name="start"
                            label="Desde"
                            isRequired={true}
                            fnElement={({ field }) => {
                                return (
                                    <DateTimePickerWithHandleError
                                        date={field.value || undefined}
                                        setDate={field.onChange}
                                        hiddenInputHour={true}
                                        handleSetError={(error) => {
                                            if (!error) return contextForm.clearErrors('start');
                                            contextForm.setError('start', { message: 'La fecha ingresada no es válida' })
                                        }}
                                    />
                                )
                            }}
                        />

                        <CustomFormField
                            control={contextForm.control}
                            name="end"
                            label="Hasta"
                            isRequired={true}
                            fnElement={({ field }) => {
                                return (
                                    <DateTimePickerWithHandleError
                                        date={field.value || undefined}
                                        setDate={field.onChange}
                                        hiddenInputHour={true}
                                        handleSetError={(error) => {
                                            if (!error) return contextForm.clearErrors('end');
                                            contextForm.setError('end', { message: 'La fecha ingresada no es válida' })
                                        }}
                                    />
                                )
                            }}
                        />
                    </form>
                </Form>
                <DialogFooter>
                    <Button
                        type="button"
                        variant='outline-primary'
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        disabled={!isValidForm}
                        form="filterRangeDate"
                    >
                        Aplicar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default FilterDateModal;