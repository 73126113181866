import { Grid } from "@mui/material"
import { useEffect, useMemo } from "react"
import { getCompany } from "../../../actions/auth"
import FilterMultiSelect from "../FilterMultiSelect"
import FilterUser from "../FilterUser"
import GroupFilterCRM from "./GroupFilterCRM"
import { useSelector } from "react-redux"

interface UserFilterAvanced {
    hook?: any,
    width?: string | number,
    showOnlyUser?: boolean,
    title?: string
}


const UserFilterAvanced = ({ hook, width, showOnlyUser, title }: UserFilterAvanced) => {
    const [{userFilter,activeUsers, activeGroups, groupFilter, usersExtraFilters, usersFilter, key, actualUser }, {setUserFilter, handleActiveGroupChange, handleUsersFilters}] = hook
    if (!activeUsers || !(actualUser && actualUser.roles && (actualUser.roles.includes('comercial') || actualUser.roles.includes('super')))) return <></> 
    let extraFields = {width}
    
    let [minAncestors, maxAncestors, diffAncestors] = useMemo(() => {
        let min = -1, max = 0

        activeGroups.map((g) => {
            if(min == -1 || g.ancestors.length < min){
                min = g.ancestors.length 
            }

            if(max < g.ancestors.length){
                max = g.ancestors.length
            }
        })
        return [min, max, max - min]

    },[activeGroups])

    return (
        <>
            <Grid item>
                <FilterUser title={title ?? "Vendedores"} value={userFilter ?? []} setValue={setUserFilter} users={activeUsers ?? []} useEmail={key == 'email'} {...extraFields} />
            </Grid>
          {!showOnlyUser && <> 
            {diffAncestors > 1 ? (
                <Grid item>
                    <GroupFilterCRM activeGroups={activeGroups} handleActiveGroupChange={handleActiveGroupChange}/>
                </Grid>
            ): (
                <Grid item>
                    <FilterMultiSelect title="Grupo" list={activeGroups} idKey="_id" getName={(g) => {
                        return g ? g.name : 'Grupo invalido'
                    }} setValue={handleActiveGroupChange} value={groupFilter}  {...extraFields}/>
                </Grid>
            )}
            {getCompany() && getCompany().additionalUserFields.filter((f) => f.type == 'select').map((f) => (
                <Grid item>
                    <FilterMultiSelect title={f.title} list={f.options.map((o) => ({ title: o, key: o }))} idKey="key" {...extraFields} getName={(g) => {
                        return g ? g.title : 'Sin definir'
                    }} setValue={handleUsersFilters(f.key)} value={usersExtraFilters[f.key] ?? ['all']} />
                </Grid>
            ))}
            </>}
        </>
    )

}


export default UserFilterAvanced


